import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import areaimage from '../../data/images/areaimage.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  image: {
    borderRadius: '2%',
    boxShadow:
      '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08))',
    marginLeft: '1%',
    height: '385px',
  },
  header: {
    maxWidth: '90%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '60px',
      maxWidth: '100%',
    },
  },
  visitButton: {
    marginLeft: '5%',
    marginRight: '5%',
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
      margin: 'auto 16px',
      height: '56px',
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      width: '134px',
    },
  },
}));

const Area = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const scrollToTop = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  const Text = (): JSX.Element => {
    return (
      <Grid item xs={12} md={6} data-aos={'fade-up'}>
        <SectionHeader
          title={t('areaTitle')}
          subtitle={t('areaSubtitle')}
          fadeUp
          titleVariant="h4"
          align={isMd ? 'left' : 'center'}
          subtitleVariant="body1"
          subtitleProps={{
            style: { color: theme.palette.primary.main },
          }}
          className={classes.header}
        />
        <div style={isMd ? {} : { textAlign: 'center' }}>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.visitButton}
            onClick={scrollToTop}
          >
            {t('areaVisitButton')}
          </Button>
        </div>
      </Grid>
    );
  };

  const HeroImage = (): JSX.Element => {
    return (
      <Grid
        item
        container
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
        md={6}
        data-aos={'fade-up'}
      >
        <Image src={areaimage} alt="Our Location" className={classes.image} />
      </Grid>
    );
  };

  return (
    <div className={className} {...rest}>
      {isMd ? (
        <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
          <Text />
          <HeroImage />
        </Grid>
      ) : (
        <Grid container justifyContent="space-between" spacing={isMd ? 4 : 2}>
          <HeroImage />
          <Text />
        </Grid>
      )}
    </div>
  );
};

export default Area;
