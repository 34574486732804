import access247 from './images/247access.svg';
import baristacoffee from './images/baristacoffee.svg';
import businessclassprinters from './images/businessclassprinters.svg';
import businessconcierge from './images/businessconcierge.svg';
import carpark from './images/carpark.svg';
import cat6 from './images/cat6.svg';
import communalarea from './images/communalarea.svg';
import corporatepantry from './images/corporatepantry.svg';
import corporatesecretarialsupport from './images/corporatesecretarialsupport.svg';
import coworkingmembership from './images/coworkingmembership.svg';
import enterprisesolutions from './images/enterprisesolutions.svg';
import eventspaces from './images/eventspaces.svg';
import housekeeping from './images/housekeeping.svg';
import itsupport from './images/itsupport.svg';
import mailmanagement from './images/mailmanagement.svg';
import meetingrooms from './images/meetingrooms.svg';
import officesupplies from './images/officesupplies.svg';
import phonebooth from './images/phonebooth.svg';
import privateoffices from './images/privateoffices.svg';
import shower from './images/shower.svg';
import virtualoffices from './images/virtualoffices.svg';
import wifi6 from './images/wifi6.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const data = () => {
  const { t } = useTranslation();
  const services = [
    {
      name: t('servicesEnterprise'),
      logo: enterprisesolutions,
    },
    {
      name: t('servicesPrivate'),
      logo: privateoffices,
    },
    {
      name: t('servicesCoworking'),
      logo: coworkingmembership,
    },
    // {
    //   name: t('servicesVirtual'),
    //   logo: virtualoffices,
    // },
    {
      name: t('servicesCorporate'),
      logo: corporatesecretarialsupport,
    },
    {
      name: t('servicesConcierge'),
      logo: businessconcierge,
    },
    {
      name: t('services247'),
      logo: access247,
    },
    {
      name: t('servicesMail'),
      logo: mailmanagement,
    },
    {
      name: t('servicesHousekeeping'),
      logo: housekeeping,
    },
    {
      name: t('servicesIT'),
      logo: itsupport,
    },
    {
      name: t('servicesCat'),
      logo: cat6,
    },
    {
      name: t('servicesWifi'),
      logo: wifi6,
    },
  ];

  const amenities = [
    {
      name: t('amenitiesPark'),
      logo: carpark,
    },
    {
      name: t('amenitiesMeeting'),
      logo: meetingrooms,
    },
    {
      name: t('amenitiesEvent'),
      logo: eventspaces,
    },
    {
      name: t('amenitiesCommunal'),
      logo: communalarea,
    },
    {
      name: t('amenitiesPhone'),
      logo: phonebooth,
    },
    {
      name: t('amenitiesCorporate'),
      logo: corporatepantry,
    },
    {
      name: t('amenitiesBarista'),
      logo: baristacoffee,
    },
    {
      name: t('amenitiesPrinters'),
      logo: businessclassprinters,
    },
    {
      name: t('amenitiesSupplies'),
      logo: officesupplies,
    },
    {
      name: t('amenitiesShower'),
      logo: shower,
    },
  ];

  const areaData = [
    [
      {
        location: {
          y: 22.2846202,
          x: 114.152546,
          address: "99 Queen's Road Central, Central, Hong Kong",
        },
      },
    ],
    [
      {
        src:
          'https://assets.maccarianagency.com/the-front/photos/coworking/place1.jpg',
        srcSet:
          'https://assets.maccarianagency.com/the-front/photos/coworking/place1@2x.jpg 2x',
        alt: '...',
      },
      {
        src:
          'https://assets.maccarianagency.com/the-front/photos/coworking/place2.jpg',
        srcSet:
          'https://assets.maccarianagency.com/the-front/photos/coworking/place2@2x.jpg 2x',
        alt: '...',
      },
      {
        src:
          'https://assets.maccarianagency.com/the-front/photos/coworking/place3.jpg',
        srcSet:
          'https://assets.maccarianagency.com/the-front/photos/coworking/place3@2x.jpg 2x',
        alt: '...',
      },
    ],
  ];
  return {
    services,
    amenities,
    areaData,
  };
};
