import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid, Avatar } from '@material-ui/core';

import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  gridWidth: {
    maxWidth: '95%',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '75%',
    },
  },
  avatar: {
    width: 60,
    height: 60,
    background: 'transparent',
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 80,
    },
  },
  image: {
    width: 60,
    height: 60,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 80,
    },
  },
}));

const Amenities = ({
  data,
  className,
  ...rest
}: ViewComponentProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <SectionHeader title={t('amenitiesTitle')} titleVariant="h3" fadeUp />
      <Grid container spacing={8} className={classes.gridWidth}>
        {data.map((item: any, index: number) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={4}
            md
            data-aos={'fade-up'}
          >
            <DescriptionListIcon
              icon={
                <Avatar className={classes.avatar}>
                  <Image
                    src={item.logo}
                    alt={item.name}
                    className={classes.image}
                  />
                </Avatar>
              }
              title={item.name}
              titleVariant="body1"
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Amenities;
