import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import { Icon } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { CardPricingStandard } from '..';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => ({
  priceCard: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.light,
      background: theme.palette.primary.main,
    },
  },
  price: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
  },
  priceBold: {
    fontWeight: 700,
    fontSize: 28,
  },
  connectButton: {
    marginTop: theme.spacing(5),
    position: 'relative',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '270px',
    [theme.breakpoints.down('sm')]: {
      height: '56px',
    },
  },
}));

const Pricings = ({ ...rest }): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const scrollToContactForm = () => {
    const contactFormElement = document.getElementById('contact-form');
    if (contactFormElement) {
      contactFormElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div {...rest}>
      <SectionHeader
        title={t('pricingTitle')}
        subtitle={t('pricingSubtitle')}
        titleVariant="h3"
        subtitleVariant="h5"
        subtitleColor="textPrimary"
        data-aos="fade-up"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            withShadow
            liftUp
            title={
              <>
                {t('pricingPrivateTitle1')}
                <br />
                {t('pricingPrivateTitle2')}
              </>
            }
            className={classes.priceCard}
            priceComponent={
              <div>
                <Typography className={classes.price}>
                  <span className={classes.priceBold}>{t('from')} $6,000</span>/
                  {t('pricingPrivateCostTime')}
                </Typography>
              </div>
            }
            features={[
              t('pricingPrivateFeature1'),
              t('pricingPrivateFeature2'),
              t('pricingPrivateFeature3'),
              t('pricingPrivateFeature4'),
              t('pricingPrivateFeature5'),
            ]}
            featureCheckComponent={<Icon fontIconClass="fas fa-check-circle" />}
            cta={<div />}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            title={
              <>
                {t('pricingCoworkingTitle1')}
              </>
            }
            liftUp
            className={classes.priceCard}
            priceComponent={
              <div>
                <Typography className={classes.price}>
                  <span className={classes.priceBold}>{t('from')} $3,500</span>/
                  {t('pricingCoworkingCostTime')}
                </Typography>
              </div>
            }
            features={[
              t('pricingCoworkingFeature1'),
              t('pricingCoworkingFeature2'),
              t('pricingCoworkingFeature3'),
              t('pricingCoworkingFeature4'),
              t('pricingCoworkingFeature5'),
            ]}
            featureCheckComponent={<Icon fontIconClass="fas fa-check-circle" />}
            cta={<div />}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            title={
              <>
                {t('pricingVirtualTitle1')}
                <br />
                {t('pricingVirtualTitle2')}
              </>
            }
            liftUp
            className={classes.priceCard}
            priceComponent={
              <div>
                <Typography className={classes.price}>
                  <span className={classes.priceBold}>{t('pricingVirtualContent')}</span>
                </Typography>
              </div>
            }
            features={[
              t('pricingVirtualFeature1'),
              t('pricingVirtualFeature3'),
              t('pricingVirtualFeature4'),
              t('pricingVirtualFeature5'),
              t('pricingVirtualFeature6'),
              t('pricingVirtualFeature7'),
            ]}
            featureCheckComponent={<Icon fontIconClass="fas fa-check-circle" />}
            cta={<div />}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} data-aos="fade-up">
          <CardPricingStandard
            variant="outlined"
            title={
              <>
                {t('pricingMeetingTitle1')}
                <br />
                {t('pricingMeetingTitle2')}
              </>
            }
            liftUp
            className={classes.priceCard}
            priceComponent={
              <div>
                <Typography className={classes.price}>
                  <span className={classes.priceBold}>{t('from')} $750</span>/
                  {t('pricingMeetingCostTime')}
                </Typography>
              </div>
            }
            features={[
              t('pricingMeetingFeature1'),
              t('pricingMeetingFeature2'),
              t('pricingMeetingFeature3'),
              t('pricingMeetingFeature4'),
              // t('pricingMeetingFeature5'),
            ]}
            featureCheckComponent={<Icon fontIconClass="fas fa-check-circle" />}
            cta={<div />}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="secondary"
        className={classes.connectButton}
        onClick={scrollToContactForm}
      >
        {t('pricingConnectButton')}
      </Button>
    </div>
  );
};

export default Pricings;
