import React from 'react';
import Membership from 'views/Membership';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { graphql } from 'gatsby';

const MembershipsPage = (): JSX.Element => {
  return <WithLayout component={Membership} layout={Main} />;
};

export default MembershipsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
