import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Amenities,
  Area,
  Contact,
  Hero,
  Pricings,
  Services,
} from "./components";
import { FloatingIcons, Section } from "components/organisms";

import { data as myData } from "./data";
import { DefaultSEO } from "components/DefaultSEO";

const useStyles = makeStyles((theme) => ({
  pricing: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "120%",
      margin: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "90%",
      minWidth: "1300px",
      margin: "auto",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "50%",
      minWidth: "1500px",
      margin: "auto",
    },
  },
  sectionAlternateForm: {
    [theme.breakpoints.up("md")]: {
      sectionAlternateForm: {
        maxWidth: "75%",
        margin: "auto",
      },
    },
  },
}));

const Membership = (): JSX.Element => {
  const { amenities, areaData, services } = myData();
  const classes = useStyles();

  return (
    <div>
      <DefaultSEO
        title="THE CENTER SPACE | The Workspace that Elevates Your Business"
        description="The Center is located at the heart of Central, Hong Kong's Central Business District. With a standing of 350 meters, The Center holds the title of Hong Kong's fifth-highest skyscraper. Located on the 76th floor of the building, one of the highest co-working space in Hong Kong, our space offers panoramic views of Victoria Harbour and Hong Kong Skyline. The Center is also surrounded by major financial, banking, and government headquarters."
      />
      <Hero />
      <FloatingIcons />
      <Section className={classes.pricing}>
        <Pricings />
      </Section>
      <Section>
        <Area data={areaData} />
      </Section>
      <Section>
        <Services data={services} />
      </Section>
      <Section>
        <Amenities data={amenities} />
      </Section>
      <div className={classes.sectionAlternateForm}>
        <Section >
          <Contact id="contact-form"/>
        </Section>
      </div>
    </div>
  );
};

export default Membership;
