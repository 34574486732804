import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button, Grid } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    marginBottom: '5vh',
    background: `url(${require('../../data/images/hero.png')}) no-repeat center center`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '500px',
    marginTop: -1,
    zIndex: 1,
  },
  header: {
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
    top: '40%',
  },
  searchButtonSm: {
    position: 'absolute',
    left: '50%',
    top: '320px',
    transform: 'translate(-50%, 0)',
    width: '50%',
    maxWidth: '300px',
    height: '56px',
    [theme.breakpoints.only('sm')]: {
      width: '300px',
    },
  },
}));

const Hero = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const scrollToContactForm = () => {
    const contactFormElement = document.getElementById('contact-form');
    if (contactFormElement) {
      contactFormElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <SectionHeader
          title={t('membershipHeroTitle')}
          subtitle={t('membershipHeroSubtitle')}
          fadeUp
          titleVariant="h4"
          subtitleVariant="body1"
          titleProps={{
            style: { color: theme.palette.primary.contrastText },
          }}
          subtitleProps={{
            style: { color: theme.palette.primary.contrastText },
          }}
          className={classes.header}
        />
      </div>
      <Grid container alignItems="center" spacing={10}>
        <Button
          className={classes.searchButtonSm}
          size="large"
          variant="contained"
          color="secondary"
          onClick={scrollToContactForm}
          style={{ backgroundColor: '#b79b75', position: 'absolute'}}
        >
          {t('tourBookingSubmitButton')}
        </Button>
      </Grid>
    </div>
  );
};

export default Hero;
