import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardMedia } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  withShadow: {
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
  },
  noShadow: {
    boxShadow: 'none',
  },
  noBorder: {
    border: 0,
  },
  noBg: {
    background: 'transparent',
  },
  media: {
    position: 'relative',
    height: '100%',
  },
  left: {
    alignItems: 'flex-start',
  },
}));

/**
 * Component to display the product card
 *
 * @param {Object} props
 */
const CardProduct = ({
  mediaClassName,
  withShadow,
  noShadow,
  noBorder,
  noBg,
  cardContent,
  mediaContent,
  align = 'left',
  className,
  ...rest
}: CardProductProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(
        'card-product',
        classes.root,
        withShadow ? classes.withShadow : {},
        noShadow ? classes.noShadow : {},
        noBorder ? classes.noBorder : {},
        noBg ? classes.noBg : {},
        className,
      )}
      {...rest}
    >
      <CardMedia
        className={clsx('card-product__media', classes.media, mediaClassName)}
      >
        {mediaContent}
      </CardMedia>
    </Card>
  );
};

export default CardProduct;
