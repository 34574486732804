import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, ListItem, Divider, List, Grid } from "@material-ui/core";
import { CardBase } from "components/organisms";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    borderRadius: "24px",
    boxShadow:
      "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
  },
  featureCheck: {
    marginRight: theme.spacing(1),
  },
  mainGrid: {
    display: "flex",
    height: "120%",
  },
  cardContent: {
    paddingLeft: "24px",
    paddingRight: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardBottom: {
    display: "inline-block",
    alignSelf: "flex-end",
    width: "100%",
    marginTop: "24px",
  },
  priceAmt: {
    marginTop: "3%",
  },
}));

/**
 * Component to display the job card
 *
 * @param {Object} props
 */
const CardPricingStandard = ({
  title,
  subtitle,
  priceComponent,
  featureCheckComponent,
  features,
  cta,
  disclaimer,
  className,
  titleProps = {},
  subtitleProps = {},
  disclaimerProps = {},
  featureTitleProps = {},
  ...rest
}: CardPricingStandardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <CardBase
      className={clsx("card-pricing-standard", classes.root, className)}
      align="left"
      {...rest}
    >
      <Grid
        container
        spacing={2}
        className={clsx(
          "card-pricing-standard__wrapper",
          classes.mainGrid,
          className
        )}
      >
        <Grid item xs={12} className="card-pricing-standard__headline">
          <Typography
            variant="h4"
            className="card-pricing-standard__title"
            align="center"
            {...titleProps}
            style={{ height: 70 }}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="body1"
              color="textSecondary"
              className="card-pricing-standard__subtitle"
              {...subtitleProps}
            >
              {subtitle}
            </Typography>
          )}
        </Grid>
        <div className={classes.cardContent}>
          <div>
            {features && (
              <Grid
                item
                xs={12}
                className="card-pricing-standard__feature-wrapper"
              >
                <List className="card-pricing-standard__feature-list">
                  {features.map((item, index) => (
                    <ListItem
                      key={index}
                      disableGutters
                      className="card-pricing-standard__feature-list-item"
                    >
                      {featureCheckComponent && (
                        <div
                          className={clsx(
                            "card-pricing-standard__feature-check",
                            classes.featureCheck
                          )}
                        >
                          {featureCheckComponent}
                        </div>
                      )}
                      <Typography
                        variant="body1"
                        className="card-pricing-standard__feature-title"
                        {...featureTitleProps}
                      >
                        {item}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </div>
          <div className={classes.cardBottom}>
            <Grid
              item
              xs={12}
              className="card-pricing-standard__divider-container"
            >
              <Divider className="card-pricing-standard__divider" />
            </Grid>
            <Grid
              item
              xs={12}
              className={clsx(
                "card-pricing-standard__content",
                classes.priceAmt
              )}
            >
              {priceComponent}
            </Grid>
          </div>
        </div>
      </Grid>
    </CardBase>
  );
};

export default CardPricingStandard;
